import React from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Button({ children, loading, size, ...rest }) {
  return (
    <button
      {...rest}
      className={clsx(styles["button-container"], styles[size])}
    >
      <span>
        {" "}
        {loading && <AiOutlineLoading3Quarters />} {children}
      </span>{" "}
    </button>
  );
}
