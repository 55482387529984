import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";


export default function Footer() {
  return (
    <footer>
      <Link
        to={"https://portfolio.fazliddin.dev"}
        target="_blank"
        className={styles["footer"]}
      >
        Mualliflik huquqi © 2024-yil fazliddin.dev
      </Link>
    </footer>
  );
}
