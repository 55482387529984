import React from "react";
import HomePage from "../pages/Home";
import Footer from "./Footer";
import Navbar from "./Navbar/index";
  
export default function AppLayout() {
  return (
    <div>
      <Navbar  /> 
      <HomePage />
      <Footer />
    </div>
  );
}
