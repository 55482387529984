import React from "react";
import courses from "../../../data/course";
import Card from "./Card";
import styles from "./index.module.scss";
import clsx from "clsx";
import Cards from "./Cards";
import { useQuery } from "react-query";
import axios from "axios";

export default function Courses() {
  const { data } = useQuery("courses", () =>
    axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/course/no-auth?fields=title,image,description`
    )
  );
  const courses = data?.data?.data;
  if (!courses?.[0]) {
    return;
  }
  return (
    <sectoin className={styles["cards-container"]} id="course">
      <h1 className={clsx("title", styles["section-title"])}>Mavjud Kurslar</h1>
      <Cards courses={courses} />
    </sectoin>
  );
}
