import React from "react";
 import Card from "./Card";
import styles from "./index.module.scss";
import Slider from "react-slick";

export default function Cards({courses}) {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow:<></>,
    prevArrow:<></>,

    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider className={styles["slider"]} {...settings}>
      {courses.map((course) => (
        <Card course={course} />
      ))}
    </Slider>
  );
}
