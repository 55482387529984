import React from "react";
import styles from "./index.module.scss";
import Button from "../../Button";
import showcaseImg from "../../../assets/showcase.png";

export default function Showcase() {
  return (
    <section className={styles["showcase-container"]} id="showcase">
      <div className={styles["text-container"]}>
        <p>Nextech onlayn ta'lim platformasi</p>
        <h1 className="title">
          Zamonaviy sohalarni <br /> birga o’rganamiz!
        </h1>
        <br />
        <a href="https://edu.nextech.uz" target="_blank">
          <Button>O'qishni boshlash</Button>
        </a>
      </div>

      <img src={showcaseImg} alt="" />
    </section>
  );
}
