import React from "react";
import Showcase from "../../components/sections/Showcase";
import Courses from "../../components/sections/Courses";
import About from "../../components/sections/About";
import Discount from "../../components/sections/Discount";
import Contacts from "../../components/sections/Contacts";

export default function HomePage() {
  return (
    <div> 
      <Showcase />
      <Courses />
      {/* <Discount /> */}
      <About />
      <Contacts/>
    </div>
  );
}
