import React, { useState } from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import ReactInputMask from "react-input-mask";
import Button from "../../Button";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default function Contacts() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ phone: "", name: "" });
  const onSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const cleanedPhoneNumber = data.phone.replace(/[+\s]/g, "");
      if (cleanedPhoneNumber.length !== 12) {    setLoading(false);

        return NotificationManager.error(
          "Iltimos raqamingizni to'liq kiriting!"
        );
      }
      await axios.post(
        `https://api.telegram.org/bot5763769039:AAH9kV6kuto7gGjNQjA-g122TXuzcMj2VI4/sendMessage`,
        {
          chat_id: "6203879790",
          text: `
            Ism:          ${data.name}\nTelefon:   ${cleanedPhoneNumber}\nTelegram:  t.me/+${cleanedPhoneNumber}
        `,
        }
      );

      setData({ phone: "", name: "" });
      NotificationManager.success(
        "Ma'lumotlaringiz jo'natildi!",
        "Tez orada siz bilan bog'lanamiz."
      );
    } catch (error) {
      NotificationManager.error(
        "Ma'lumotlaringiz jo'natilmadi!",
        "Qaytadan urunib ko'ring"
      );
      console.error(error);
    }
    setLoading(false);
  };
  return (
    <section className={styles["contacts-container"]}  id="contact">
      <div className={styles["title"]}>
        <h1 className="title">Biz bilan bog’laning!</h1>
        <p>Istalgan savolingiz bo’lsa biz bilan bog’laning!</p>
      </div>
      <div className={styles["contacts-border"]}>
        <div className={styles["contacts"]}>
          <div className={styles["socials"]}>
            <h1>Kontakt Ma’lumotlari!</h1>
            <p>Sizga 24/7 javob qaytaramiz</p>
            <div className={styles["links"]}>
              <Link to="tel:+998881470210" target="_blank">
                <FaPhone size={25} />
                <p>+998 (88) 147-02-10</p>
              </Link>{" "}
              <Link to="https://www.instagram.com/nextech.uz/" target="_blank">
                <AiFillInstagram size={25} />
                <p>@nextech.uz</p>
              </Link>{" "}
              <Link to="https://t.me/nextechuz" target="_blank">
                <FaTelegram size={25} />
                <p>@nextechuz</p>
              </Link>
            </div>
          </div>
          <form onSubmit={onSubmit}>
          <h1>Aloqaga chiqish</h1>

            <div className={styles["input-wrapper"]}>
              <label htmlFor="name">Ismingiz</label>
              <input
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name}
                type="text"
                name="name"
                id="name"
                required
                placeholder="Ismingizni kiriting"
              />
            </div>{" "}
            <div className={styles["input-wrapper"]}>
              <label htmlFor="phone">Telefon raqamingiz</label>
              <ReactInputMask
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                value={data.phone}
                required
                mask="+\9\98 99 999 99 99"
                maskChar=" "
                name="phone"
                id="phone"
                placeholder="Telefon raqamingizni kiriting"
              />
            </div>
            <Button
              size={"small"}
              style={{ display: "block", width: "100%" }}
              loading={loading}
            >
              Yuborish
            </Button>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </section>
  );
}
