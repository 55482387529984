import React, { useState } from "react";
import styles from "./index.module.scss";
import logo from "../../assets/logo-light.png";
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import clsx from "clsx";
import Button from "../../components/Button";
import routes from "../../pages/routeConfig";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  return (
    <div className={clsx(styles["navbar-container"], open && styles["active"])}>
      <nav className={styles["navbar"]}>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>

        <div className={styles["links"]}>
          {routes.map((el) => (
            <a href={el.path} key={el.id}>
              {el.name}
            </a>
          ))}
          <a href="https://edu.nextech.uz" target="_blank">
            <Button size="small">Kirish</Button>
          </a>
        </div>

        <div className={styles["menuBtn"]} onClick={() => setOpen(true)}>
          <RxHamburgerMenu size={30} />
        </div>
      </nav>
      <div className={styles["menu"]}>
        <div className={styles["closeBtn"]} onClick={() => setOpen(false)}>
          <RxCross1 size={30} />
        </div>
        <div className={styles["menu-links"]}>
          {routes.map((el) => (
            <a href={el.path} key={el.id}>
              {el.name}
            </a>
          ))}{" "}
          <a href="https://edu.nextech.uz" target="_blank"   >
            <Button size="small">Kirish</Button>
          </a> 
        </div>
      </div>
    </div>
  );
}
