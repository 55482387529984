import React from "react";
import styles from "./index.module.scss";
import image from "../../../assets/about.png";

export default function About() {
  return (
    <section className={styles["about"]} id="about">
      <img src={image} alt="" />
      <div className={styles["text"]}>
        <h1 className="title">Platforma haqida</h1>
        <p>
          Biz - Nextech jamoasi, raqamli sohalarda yetakchi bo'lishingiz uchun kerakli
          bilimlarni o'rganishda sizga yordam beramiz. Bizning kurslarimiz sizni
          kuchli va oson usullarda rivojlantirishga yordam beradi
        </p>
      </div>
    </section>
  );
}
