const routes = [
  {
    path: "#contact",
    name: "Aloqa",
    id: 1,
  },
  // {
  //   path: "#discount",
  //   name: "Chegirma",
  //   id: 3,
  // },
  {
    path: "#about",
    name: "Biz haqimizda",
    id: 2,
  },
];

export default routes;
