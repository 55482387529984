import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

export default function Card({ course }) {
  return (
    <Link
      to="https://edu.nextech.uz"
      className={styles["card-container"]}
    >
      <div className={styles["card"]}>
        <img src={`${process.env.REACT_APP_API_URL}${course.image.location}`} alt="" />
        <h1  >{course.title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: course.description || "",
          }}
        ></p>
      </div>
    </Link>
  );
}
